/* eslint-disable */
import * as shared from '../shared';

googletag.cmd.push(function () {
  // hospital_top
  googletag.defineSlot(
    '/21678620621/medicalnote_hospital_top_20200506',
    [[728, 90], [728, 135]],
    'div-gpt-ad-1588744304558-0'
  ).addService(googletag.pubads());

  // hospital_bottom
  googletag.defineSlot(
    '/21678620621/medicalnote_hospital_bottom_20181003',
    [[728, 90], [728, 135]],
    'div-gpt-ad-1538559609638-0'
  ).addService(googletag.pubads());

  // hospital_rightup
  googletag.defineSlot(
    '/21678620621/medicalnote_hospital_right_20181003',
    [[300, 600], [160, 600], [230, 675]],
    'div-gpt-ad-1538559528592-0'
  ).addService(googletag.pubads());

  // hospital_rightdown
  googletag.defineSlot(
    '/21678620621/medicalnote_hospital_right_house_20180912',
    [[300, 250], [300, 310], [300, 100]],
    'div-gpt-ad-1536722257866-0'
  ).addService(googletag.pubads());

  shared.setDiseaseKeyValue();
  shared.setSymptomKeyValue();
  shared.setContentTypeKeyValue();
  shared.setHospitalDepartmentKeyValue();
  shared.setEnvKeyValue();
  shared.setAdTagKeyValue();
  shared.setPathKeyValue();
  shared.setDoctorKeyValue();

  if (!shared.isMobile()) googletag.pubads().enableSingleRequest();
  googletag.pubads().collapseEmptyDivs();
  googletag.enableServices();
});
/* eslint-enable */
